import { useEffect, useState } from "react";
import { getParticipantsWorkflowAverage } from "../../../../../../../utils/skillsAssessment/calc";
import {
  canManagersUpdate,
  evaluationColors,
} from "../../../../../../../utils/skillsAssessment/constants";
import { checkUserRoleForParticipant } from "../../../../../../../utils/skillsAssessment/filter";
import { validateWorkflowActions } from "../../../../../../../utils/skillsAssessment/validation";
import Avatar from "../../../../../../Common/Avatar";
import MaterialTextField from "../../../../../../Common/MaterialTextField";
import SimpleCheckbox from "../../../../../../Common/SimpleCheckbox";
import SimplePagination from "../../../../../../SimplePagination";
import EvaluationLine from "../../overview/includes/EvaluationLine";

const colSize = 150;

const ParticipantsWorkflowTable = ({
  toolAdministration,
  participants,
  participantsWorkflow,
  participantsComplementaryWorkflow,
  handleSelectParticipant,
  handleSendInvites,
  handleSendSelectionEvaluatorsInvites,
  handleNotifyManagers,
  handleNotifyManagersSelectionEvaluator,
  handleSendNextStage,
  maxScale,
  maxPotential,
  filterStage,
  isAdmin,
  enableSecondEvaluator,
  enableThirdEvaluator,
  dates,
  composition,
  enabledFields,
  hideNextStage,
  totalPages,
  page,
  setPage,
  filter,
  setFilter,
  loggedUser,
}) => {
  const [selectedParticipantIds, setSelectedParticipantIds] = useState([]);

  const { evaluationConfig = {}, generalOptions } = toolAdministration || {};

  function setInitialSelected() {
    setSelectedParticipantIds(participants.map(({ id }) => id));
  }

  useEffect(() => {
    setInitialSelected();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStage, page, participants]);

  function isAllSelected() {
    return (
      participants.length > 0 &&
      selectedParticipantIds.length === participants.length
    );
  }

  function isParticipantSelected(selectedId) {
    return selectedParticipantIds.indexOf(selectedId) > -1;
  }

  function toggleSelectedAll() {
    if (isAllSelected()) {
      setSelectedParticipantIds([]);
    } else if (participants.length > 0) {
      setSelectedParticipantIds(participants.map(({ id }) => id));
    }
  }

  function toggleSelectedId(selectedId) {
    const indexOf = selectedParticipantIds.indexOf(selectedId);

    if (indexOf > -1) {
      const updated = [...selectedParticipantIds];
      updated.splice(indexOf, 1);

      setSelectedParticipantIds(updated);
    } else {
      setSelectedParticipantIds((current) => {
        return [...current, selectedId];
      });
    }
  }

  function changeFilter(str) {
    setFilter(str);
    if (page !== 1) setPage(1);
  }

  const getValuationLine = (
    evaluation,
    colorInfo,
    maxValue,
    toFixed = 2,
    append = "",
    fontSize = "16px"
  ) => {
    return (
      <EvaluationLine
        evaluation={evaluation}
        colorInfo={colorInfo}
        maxValue={maxValue}
        append={append}
        fontSize={fontSize}
        toFixed={toFixed}
      />
    );
  };

  const getWorkflowValuationLine = (workflowAverage, evaluationSlug) => {
    const evaluation = workflowAverage[evaluationSlug];
    const colorInfo = evaluationColors[evaluationSlug];
    const max = evaluationSlug === "potential" ? maxPotential : maxScale;

    return getValuationLine(evaluation, colorInfo, max);
  };

  const onSelectParticipant = (participant) => {
    handleSelectParticipant(participant);
  };

  const onSendInvites = () => {
    changeFilter("");
    handleSendInvites(selectedParticipantIds);
  };

  const onSendSelectionEvaluatorsInvites = () => {
    changeFilter("");
    handleSendSelectionEvaluatorsInvites(selectedParticipantIds);
  };

  const onNotifyManagers = () => {
    changeFilter("");
    handleNotifyManagers(selectedParticipantIds);
  };

  const onNotifyManagersSelectionEvaluators = () => {
    changeFilter("");
    handleNotifyManagersSelectionEvaluator();
  };

  const onSendNextStage = () => {
    changeFilter("");
    handleSendNextStage(selectedParticipantIds);
    setSelectedParticipantIds([]);
  };

  function getValidationErrors() {
    const errors = {};

    participants.forEach((participant) => {
      if (participant?.id) {
        const loggedUserRole = checkUserRoleForParticipant(
          loggedUser,
          participant
        );
        const lineErrors = validateWorkflowActions(
          participant,
          participantsWorkflow[participant.id],
          dates,
          enableSecondEvaluator,
          enableThirdEvaluator,
          composition,
          evaluationConfig,
          participantsComplementaryWorkflow[participant.id],
          loggedUserRole,
          loggedUser
        );
        if (lineErrors.length > 0) errors[participant.id] = lineErrors;
      }
    });

    const cantUpdateNextStage =
      filterStage !== "VALIDATION" &&
      selectedParticipantIds.filter((id) => errors[id]).length > 0 &&
      !generalOptions?.disableRequiredQuestionsValidation;

    const haveErrors = Object.keys(errors).length > 0;

    const periodEndedError = () => {
      if (errors) {
        for (let key in errors) {
          const hasPeriodEnded = errors[key].filter(
            (error) => error.slug === "validationEnded"
          );
          return hasPeriodEnded;
        }
      }
      return [];
    };

    return {
      errors,
      cantUpdateNextStage,
      haveErrors,
      periodEndedError: periodEndedError(),
    };
  }

  function getPagination(menuPlacement) {
    return (
      <SimplePagination
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        menuPlacement={menuPlacement}
      />
    );
  }

  const { errors, haveErrors, cantUpdateNextStage, periodEndedError } =
    getValidationErrors();

  const cantSendToNextStage =
    selectedParticipantIds.length === 0 || cantUpdateNextStage;

  const showEval2 =
    !enabledFields?.secondEvaluator?.disabled && enableSecondEvaluator;
  const showEval3 =
    !enabledFields?.thirdEvaluator?.disabled && enableThirdEvaluator;

  return (
    <div>
      <div
        className="row"
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          className="col-xs-12 col-md-4"
          style={{
            padding: "0px",
            display: "flex",
          }}
        >
          <MaterialTextField
            label="Pesquisar Participante"
            value={filter}
            onChange={(e) => changeFilter(e.target.value)}
          />
        </div>
        <div
          className="col-xs-12 col-md-8"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: 0,
            gap: 10,
          }}
        >
          {isAdmin && filterStage === "SELF_EVALUATION" && (
            <button
              className="btn btn-purple"
              type="button"
              onClick={onSendInvites}
              disabled={selectedParticipantIds.length === 0}
            >
              <i className="fas fa-clipboard-list" /> Enviar pesquisa aos
              participantes
            </button>
          )}
          {isAdmin && filterStage === "SELECTION_EVALUATORS" && (
            <button
              className="btn btn-purple"
              type="button"
              onClick={onSendSelectionEvaluatorsInvites}
              disabled={selectedParticipantIds.length === 0}
            >
              <i className="fas fa-clipboard-list" /> Enviar seleção de av. aos
              participantes
            </button>
          )}
          {isAdmin && filterStage === "SELECTION_EVALUATORS" && (
            <button
              className="btn btn-purple"
              type="button"
              onClick={onNotifyManagersSelectionEvaluators}
              disabled={selectedParticipantIds.length === 0}
            >
              <i className="fas fa-clock" /> Notificar gestores
            </button>
          )}
          {isAdmin && canManagersUpdate(filterStage) && (
            <button
              className="btn btn-purple"
              type="button"
              onClick={onNotifyManagers}
              disabled={selectedParticipantIds.length === 0}
            >
              <i className="fas fa-clock" /> Notificar gestores
            </button>
          )}
          {!hideNextStage && (
            <button
              className="btn btn-success"
              type="button"
              onClick={onSendNextStage}
              disabled={cantSendToNextStage}
            >
              <i className="fas fa-arrow-right" /> Enviar para próximo estágio
            </button>
          )}
        </div>
      </div>
      {filter && (
        <div className="row">
          <div>{`Resultados da pesquisa: ${participants.length} `}</div>
          <a onClick={() => changeFilter("")} style={{ cursor: "pointer" }}>
            Limpar <i className="fas fa-times" />
          </a>
        </div>
      )}
      <div
        className="row text-bold text-danger"
        align="right"
        style={{ height: "15px" }}
      >
        {periodEndedError && periodEndedError[0]?.message}
        <br />
        {haveErrors && !periodEndedError[0]
          ? `Preenchimentos pendentes na avaliação de: ${Object.keys(errors)
              .map(
                (pID) =>
                  participants.find((p) => p.id === Number(pID))
                    ?.memberParticipant?.name
              )
              .join(", ")}`
          : ""}
      </div>
      <div className="row" style={{ paddingTop: "25px" }}>
        {getPagination()}
      </div>
      <div className="row">
        <table className="simple-table">
          <thead>
            <tr style={{ fontSize: "11px" }}>
              <td width={50}>
                <SimpleCheckbox
                  checked={isAllSelected()}
                  onChange={toggleSelectedAll}
                />
              </td>{" "}
              <td>Participante</td>
              {filterStage !== "SELECTION_EVALUATORS" && (
                <>
                  <td width={colSize}>AUTOAVALIAÇÃO</td>
                  <td width={colSize}>AV. GESTOR</td>
                </>
              )}
              {!enabledFields.potential.disabled && (
                <td width={colSize}>POTENCIAL</td>
              )}
              {(filterStage === "SELECTION_EVALUATORS" ||
                filterStage === "CONCLUDED") && (
                <>
                  <td width={colSize}>GESTOR IMEDIATO</td>
                  <td width={colSize}>CLIENTE</td>
                  <td width={colSize}>FORNECEDOR</td>
                </>
              )}
              {showEval2 && <td width={colSize}>CLIENTE</td>}
              {showEval3 && <td width={colSize}>FORNECEDOR</td>}
              <td width={colSize}>Validação</td>
            </tr>
          </thead>
          <tbody className="hover-lines">
            {participants.length === 0 && (
              <tr>
                <td colSpan="100">
                  <h5 align="center">Nenhum resultado</h5>
                </td>
              </tr>
            )}
            {participants
              .sort((a, b) =>
                a?.memberParticipant?.name?.localeCompare(
                  b?.memberParticipant?.name
                )
              )
              .map((participant) => {
                const { participantsAverage } = getParticipantsWorkflowAverage(
                  toolAdministration,
                  [participant]
                );
                const [average] = participantsAverage;
                const { workflowAverage } = average;
                const onClick = {
                  onClick: () =>
                    !periodEndedError[0] && onSelectParticipant(participant),
                  style: periodEndedError[0]
                    ? { cursor: "not-allowed" }
                    : { cursor: "pointer" },
                };

                return (
                  <tr key={participant.id}>
                    <td>
                      <SimpleCheckbox
                        checked={isParticipantSelected(participant.id)}
                        onChange={() => toggleSelectedId(participant.id)}
                      />
                    </td>
                    <td {...onClick}>
                      <div
                        className="row"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Avatar
                          src={
                            participant?.memberParticipant?.userData
                              ?.avatarBlobId
                          }
                          name={participant.memberParticipant.name}
                          style={{
                            width: "30px",
                            height: "30px",
                            marginRight: "15px",
                          }}
                        />
                        <div>
                          {participant.memberParticipant.name}
                          <h4
                            style={{
                              color: "#999",
                              fontSize: "10px",
                              width: "100%",
                              margin: "3px 0px",
                            }}
                          >
                            {participant.memberParticipant.email}
                          </h4>
                        </div>
                      </div>
                    </td>
                    {filterStage !== "SELECTION_EVALUATORS" && (
                      <>
                        <td {...onClick}>
                          {getWorkflowValuationLine(
                            workflowAverage,
                            "selfEvaluation"
                          )}
                        </td>
                        <td {...onClick}>
                          {getWorkflowValuationLine(
                            workflowAverage,
                            "managerEvaluation"
                          )}
                        </td>
                      </>
                    )}
                    {!enabledFields.potential.disabled && (
                      <td {...onClick}>
                        {getWorkflowValuationLine(workflowAverage, "potential")}
                      </td>
                    )}
                    {showEval2 && (
                      <td {...onClick}>
                        {getWorkflowValuationLine(
                          workflowAverage,
                          "secondEvaluator"
                        )}
                      </td>
                    )}
                    {showEval3 && (
                      <td {...onClick}>
                        {getWorkflowValuationLine(
                          workflowAverage,
                          "thirdEvaluator"
                        )}
                      </td>
                    )}
                    {(filterStage === "SELECTION_EVALUATORS" ||
                      filterStage === "CONCLUDED") && (
                      <>
                        <td>
                          {participant?.manager?.name || "Não informado"}
                          <h4
                            style={{
                              color: "#999",
                              fontSize: "10px",
                              width: "200px",
                              margin: "3px 0px",
                            }}
                          >
                            {participant?.manager?.email || ""}
                          </h4>
                        </td>
                        <td>
                          {participant?.secondEvaluator?.name ||
                            "Não informado"}
                          <h4
                            style={{
                              color: "#999",
                              fontSize: "10px",
                              width: "200px",
                              margin: "3px 0px",
                            }}
                          >
                            {participant?.secondEvaluator?.email || ""}
                          </h4>
                        </td>
                        <td>
                          {participant?.thirdEvaluator?.name || "Não informado"}
                          <h4
                            style={{
                              color: "#999",
                              fontSize: "10px",
                              width: "200px",
                              margin: "3px 0px",
                            }}
                          >
                            {participant?.thirdEvaluator?.email || ""}
                          </h4>
                        </td>
                      </>
                    )}
                    <td align="center" {...onClick}>
                      {errors[participant.id]?.length > 0 ? (
                        <div
                          title={errors[participant.id]
                            .map(({ message }) => message)
                            .join(", ")}
                        >
                          <i className="fas fa-times text-error" />
                        </div>
                      ) : (
                        <i className="fas fa-check text-success" />
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="row" style={{ paddingBottom: "35px" }}>
        {getPagination("top")}
      </div>
    </div>
  );
};

export default ParticipantsWorkflowTable;
