/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { defineMessages } from "react-intl";
import { connect } from "react-redux";
import ActionsPlanOverview from "../../../../Common/ActionPlan/ActionsPlanOverview";
import ButtonExportExcel from "../../../../Common/ButtonExportExcel";
import ButtonGeneratePdf from "../../../../Common/ButtonGeneratePdf";
import { simpleSaveAsExcel } from "../../../../Common/Export/ExportCSV";
import ConsolidationOverview from "../../../../Common/Kpi/ConsolidationOverview";
import PerformanceEvaluations from "../../../../Common/Kpi/PerformanceEvaluations";
import ProcessKpisDetailsTable from "../../../../Common/Kpi/ProcessKpisDetailsTable";
import MaterialTextField from "../../../../Common/MaterialTextField";
import SelectMemberModal from "../../../../Common/SelectMemberModal";

import {
  getActionsPlanStates,
  getCompanyMembersAndParticipants,
  getKpiStates,
  getSelectedToolStates,
} from "../../../../customMapStates";

import {
  getGoalTypeOptions,
  getKpi_MU_options,
  getMeasurementOptions,
  getRelationshipTypeOptions,
} from "../../../../../constants/kpis";

import LoadingDialog from "components/Layouts/LoadingDialog";
import { getMonthOptions } from "../../../../../constants/monthsConstants";
import {
  getOnlySlugValues,
  getOnlySublevelArrays,
} from "../../../../../utils/ArrayUtils";
import { checkAccess } from "../../../../../utils/accessLevels";
import {
  getTotalActionsByInsights,
  groupAllActionsByRelated,
} from "../../../../../utils/actionsPlan/filter";
import { useFilteredEntities } from "../../../../../utils/entities/hooks";
import { getDisplayTargets } from "../../../../../utils/kpis/calc";
import { getSingleIndicatorResults } from "../../../../../utils/kpis/calc2";
import { displayTransformValue } from "../../../../../utils/kpis/display";
import {
  filterOnlyIndicatorsOnTree,
  getFilteredOverviewTree,
  getIndicatorsRelationTree,
} from "../../../../../utils/kpis/filter";
import { useSimpleToolAdministration } from "../../../../../utils/kpis/hooks";
import { getIndicatorsWithActions } from "../../../../../utils/skillsAssessment/filter";
import { translatedText } from "../../../../../utils/translationUtils";
import MaterialSingleSelect from "../../../../Common/MaterialSingleSelect";

const messages = defineMessages({
  kpi_tab_administration_subthemes: {
    id: "kpi_tab_administration_subthemes",
  },
  kpi_tab_management_details: { id: "kpi_tab_management_details" },
  kpi_table_text_goals: { id: "kpi_table_text_goals" },
  kpi_acp_table_indicators: { id: "kpi_acp_table_indicators" },
  kpi_options_gt_indicator: { id: "kpi_options_gt_indicator" },
  acp_main_title: { id: "acp_main_title" },
  gb_filters_title: { id: "gb_filters_title" },
  kpi_filter_opt1: { id: "kpi_filter_opt1" },
  kpi_filter_opt2: { id: "kpi_filter_opt2" },
  kpi_filter_opt3: { id: "kpi_filter_opt3" },
  kpi_filter_opt4: { id: "kpi_filter_opt4" },
  cenary: { id: "global.cenary" },
  theme: { id: "global.theme" },
  objective: { id: "global.objective" },
  responsible: { id: "global.responsible" },
  id: {
    id: "global.id",
  },
});

const translation = (id) => translatedText(id, messages);

const KPI = (props) => {
  const {
    allKpiAdministration,
    allActionsPlans,
    selectedTool = {},
    isLoading,
    filterData,
    selectAndOpenInsight,
    loggedUser,
    companyMembersAndParticipants,
  } = props;

  const [activeTab, setActiveTab] = useState("performanceTab");
  const { kpiAdministration = {} } = useSimpleToolAdministration({
    allKpiAdministration,
    selectedTool,
    isLoading,
  });
  const [displayProcesses, setDisplayProcesses] = useState([]);
  const [boardGoals, setBoardGoals] = useState([]);
  const [periodFilter, setPeriodFilter] = useState({});
  const [filteredGoals, setFilteredGoals] = useState([]);
  const [filterByResponsible, setFilterByResponsible] = useState([]);
  const [memberModalOpen, setMemberModalOpen] = useState(false);

  const { periodCycle = {} } = kpiAdministration || {};

  const [entities, setEntities] = useState({
    filteredOnlyQuestions: [],
    filteredOnlyInsights: [],
    onlyInsightIds: [],
  });

  const { entities: filteredEntities } = useFilteredEntities({
    selectedTool,
    allSceneries: props.allSceneries,
    allThemes: props.allThemes,
    allQuestions: props.allQuestions,
    allAnswers: props.allAnswers,
  });

  let { themes: filterThemes = [], questions: filterQuestions = [] } =
    filterData;

  filterThemes = filterThemes?.length > 0 ? filterThemes : ["nothing"];
  filterQuestions = filterQuestions?.length > 0 ? filterQuestions : ["nothing"];

  useEffect(() => {
    if (periodCycle && !periodFilter.start) setPeriodFilter(periodCycle);
  }, [periodCycle]);

  const isPeriodFilterActive = !_.isEqual(periodFilter, periodCycle);
  const {
    allSceneries = [],
    allThemes = {},
    allQuestions = {},
    allAnswers = {},
  } = filteredEntities;

  const { filteredOnlyQuestions = [], filteredOnlyInsights = [] } = entities;

  useEffect(() => {
    const onlyInsights = getOnlySublevelArrays(allAnswers);
    const onlyInsightIds = getOnlySlugValues(onlyInsights, "id");

    const filteredOnlyQuestions = [];
    const filteredOnlyQuestionIds = [];
    const filteredOnlyInsights = [];

    Object.keys(allQuestions).forEach((themeId) => {
      const themeQuestions = allQuestions[themeId];

      if (
        filterData.themes &&
        filterData.themes.indexOf(parseInt(themeId, 10)) > -1 &&
        themeQuestions
      ) {
        themeQuestions.forEach((questionInfo) => {
          if (filterData.questions?.includes(questionInfo.id)) {
            filteredOnlyQuestions.push(questionInfo);
            filteredOnlyQuestionIds.push(questionInfo.id);
          }
        });
      }
    });

    Object.keys(allAnswers).forEach((questionId) => {
      const questionInsights = allAnswers[questionId];
      const filteredByResponsibleInsights = questionInsights.filter(
        (insight) => {
          if (filterByResponsible.length === 0) return true;
          return filterByResponsible.includes(insight.responsibleMember?.id);
        }
      );

      if (filteredOnlyQuestionIds.indexOf(parseInt(questionId, 10)) > -1) {
        filteredByResponsibleInsights.forEach((insightInfo) => {
          filteredOnlyInsights.push(insightInfo);
        });
      }
    });

    const newEntities = {
      onlyInsights,
      onlyInsightIds,
      filteredOnlyQuestions,
      filteredOnlyQuestionIds,
      filteredOnlyInsights,
    };

    if (!_.isEqual(entities, newEntities)) {
      setEntities(newEntities);
    }
  }, [filteredEntities, filterData, filterByResponsible]);

  // Filtragem a partir da Estrutura filtrada

  useEffect(() => {
    const { tree: newIndicatorsRelation, goals: newBoardGoals } =
      getIndicatorsRelationTree(
        selectedTool.id,
        allSceneries,
        allThemes,
        allQuestions,
        allAnswers
      );

    const filteredRelation = getFilteredOverviewTree(
      kpiAdministration,
      newIndicatorsRelation,
      {
        themes: filterThemes,
        questions: filterQuestions,
        insights: filteredOnlyInsights.map((insight) => insight.id),
      },
      periodFilter
    );

    if (!_.isEqual(displayProcesses, filteredRelation))
      setDisplayProcesses(filteredRelation);

    if (!_.isEqual(boardGoals, newBoardGoals)) setBoardGoals(newBoardGoals);

    const fGoals = filterOnlyIndicatorsOnTree(filteredRelation);
    const finalFilteredGoals =
      filterByResponsible.length === 0
        ? fGoals
        : fGoals
            .map((goal) => {
              const relatedInsights =
                entities.filteredOnlyInsights.filter((insight) =>
                  goal?.relatedInsights?.includes(insight.id)
                ) || [];

              const { responsibleMember = {} } = relatedInsights[0] || {};

              const newAvatarId =
                responsibleMember?.userData?.avatarBlobId || goal.avatar;
              const newResponsibleName =
                responsibleMember?.name || goal.responsibleName;

              return {
                goal: {
                  ...goal,
                  avatarId: newAvatarId,
                  responsibleName: newResponsibleName,
                },
                relatedInsights,
              };
            })
            .filter(({ relatedInsights }) => relatedInsights?.length > 0)
            .map(({ goal }) => goal);

    if (!_.isEqual(finalFilteredGoals, filteredGoals)) {
      setFilteredGoals(finalFilteredGoals);
    }
  }, [
    selectedTool.id,
    kpiAdministration,
    filterThemes,
    filterQuestions,
    filteredEntities,
    periodFilter,
    entities,
  ]);

  const updateFilter = (filterSlug = "start", newValue) => {
    setPeriodFilter({ ...periodFilter, [filterSlug]: newValue });
  };

  const handleFilterByResponsible = (selected) => {
    setMemberModalOpen(false);
    setFilterByResponsible(selected);
  };

  const getResponsiblesValueDisplayText = (maxNames = 2) => {
    if (filterByResponsible.length === 0) return "- Selecione -";

    const slicedLimit = filterByResponsible.slice(0, maxNames);
    const slicedLimitNames = slicedLimit.map((responsibleId) => {
      const member = companyMembersAndParticipants.find(
        (member) => member.id === responsibleId
      );
      return member.name;
    });

    const slicedLimitNamesText = slicedLimitNames.join(", \n");

    return `${slicedLimitNamesText} ${
      filterByResponsible.length > maxNames
        ? `+${filterByResponsible.length - maxNames}`
        : ""
    }`;
  };

  const resetFilter = () => {
    setPeriodFilter(periodCycle);
    setFilterByResponsible([]);
  };

  function selectAndOpenKpiInsight(goalInfo) {
    if (goalInfo.relatedInsights.length === 1) {
      selectAndOpenInsight(goalInfo.relatedInsights[0]);
      return;
    }

    let filtered = {};
    goalInfo.relatedInsights.forEach((insightId) => {
      const insight = filteredOnlyInsights?.find(
        (insightInfo) => insightInfo?.id === insightId
      );

      if (insight?.responsibleMember?.id === loggedUser.id) {
        filtered = insight;
      }
    });

    if (filtered?.id) selectAndOpenInsight(filtered.id);
    if (!filtered?.id && goalInfo.relatedInsights[0])
      selectAndOpenInsight(goalInfo.relatedInsights[0]);
  }

  const administrativeAccess = checkAccess([
    "OWNER",
    "ADMIN",
    "MODERATOR",
    "MANAGER",
  ]);

  const appendProps = {
    companyMembersAndParticipants,
    relationshipTypeOptions: getRelationshipTypeOptions(),
    measurementUnityOptions: getKpi_MU_options(),
    goalTypeOptions: getGoalTypeOptions(),
    kpiAdministration,
    isAdmin: administrativeAccess,
    displayProcesses,
    filterThemes,
    filteredGoals,
    allSceneries,
    allThemes,
    allQuestions,
    allAnswers,
    filteredOnlyQuestions,
    filteredOnlyInsights,
    selectAndOpenInsight: selectAndOpenKpiInsight,
    isPeriodFilterActive,
    periodFilter,
    selectedToolId: selectedTool.id,
  };

  const renderTranslations = (ids) => {
    return (
      <ul>
        {ids.map((id) => (
          <li key={id}>{translation(id)}</li>
        ))}
      </ul>
    );
  };

  const getFilterMessage = () => {
    const tab_1_filters = [
      "kpi_filter_opt1",
      "kpi_filter_opt2",
      "kpi_filter_opt3",
      "kpi_filter_opt4",
    ];

    return renderTranslations(tab_1_filters);
  };

  const displayFilters = () => {
    const monthOptions = getMonthOptions();

    return (
      <div className="row hidden-print">
        <SelectMemberModal
          open={memberModalOpen}
          title="Select Member"
          toggleOpen={() => setMemberModalOpen(!memberModalOpen)}
          onConfirm={(selected) => handleFilterByResponsible(selected)}
          membersList={companyMembersAndParticipants}
          initialSelected={filterByResponsible}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="col-xs-6 col-md-3" style={{ paddingLeft: "0px" }}>
            <MaterialSingleSelect
              id="startPeriodFilter"
              label="Início"
              options={monthOptions}
              value={periodFilter.start}
              onChange={(e) => updateFilter("start", e.target.value)}
              defaultOption={false}
            />
          </div>
          <div className="col-xs-6 col-md-3">
            <MaterialSingleSelect
              id="endPeriodFilter"
              label="Fim"
              options={monthOptions}
              value={periodFilter.end}
              onChange={(e) => updateFilter("end", e.target.value)}
              defaultOption={false}
            />
          </div>
          <div className="col-xs-6 col-md-3">
            <MaterialTextField
              label="Responsável"
              value={getResponsiblesValueDisplayText(2)}
              title={getResponsiblesValueDisplayText(40)}
              onClick={() => setMemberModalOpen(true)}
              inputStyle={{ cursor: "pointer" }}
            />
          </div>
          <div className="col-xs-6 col-md-3">
            <button
              className="btn"
              onClick={resetFilter}
              disabled={
                !isPeriodFilterActive && filterByResponsible.length === 0
              }
            >
              Limpar filtro
            </button>
          </div>
        </div>
        {filteredOnlyInsights?.length === 0 && (
          <div className="col-xs-12">
            <h4>Nenhum resultado encontrado</h4>
          </div>
        )}
        {isPeriodFilterActive && (
          <div className="col-xs-8">
            <div className="row">
              <h5>{translation("gb_filters_title")}:</h5>
              {getFilterMessage()}
            </div>
          </div>
        )}
      </div>
    );
  };

  const performanceTabTitle = (
    <div>
      <i className="fas fa-tachometer-alt" />
      &nbsp;&nbsp; Performance
    </div>
  );

  const processTabTitle = (
    <div>
      <i className="fas fa-tasks" />
      &nbsp;&nbsp; {translation("kpi_tab_administration_subthemes")}
    </div>
  );

  const detailsTabTitle = (
    <div>
      <i className="far fa-chart-bar" />
      &nbsp;&nbsp; {translation("kpi_tab_management_details")}
    </div>
  );

  const actionsPlanTabTitle = (
    <div>
      <i className="fas fa-list-ol" />
      &nbsp;&nbsp; {translation("acp_main_title")}
    </div>
  );

  const displayTabContent = (contentTab, TabContent) => {
    return (
      activeTab === contentTab && (
        <div className="tab-content" style={{ marginTop: "10px" }}>
          {!true && (
            <div className="filter-controll col-xs-12 text-right">
              <button className="btn btn-info">Filtros</button>
            </div>
          )}
          <div className="col-xs-12" style={{ padding: "0px" }}>
            {TabContent}
          </div>
        </div>
      )
    );
  };

  function handleExcelExport() {
    const unityOptions = getKpi_MU_options();
    const measurementOptions = getMeasurementOptions();

    const headers = [
      {
        header: translation("id"),
        key: "kpiId",
        width: 10,
      },
      {
        header: translation("cenary"),
        key: "cenary",
        width: 30,
      },
      {
        header: translation("theme"),
        key: "theme",
        width: 30,
      },
      {
        header: translation("objective"),
        key: "objective",
        width: 60,
      },
      {
        header: "Indicador",
        key: "indicator",
        width: 60,
      },
      {
        header: "Unidade",
        key: "measurementUnity",
        width: 20,
      },
      {
        header: "Medição",
        key: "measurement",
        width: 10,
      },
      {
        header: "Mínimo",
        key: "gate",
        width: 20,
      },
      {
        header: "Planejado",
        key: "appropriated",
        width: 20,
      },
      {
        header: "Superado",
        key: "exceeded",
        width: 20,
      },
      {
        header: "Ano anterior",
        key: "lastPeriod",
        width: 20,
      },
      {
        header: "Atual",
        key: "actual",
        width: 20,
      },
      {
        header: "Score (%)",
        key: "score",
        width: 15,
      },
      {
        header: translation("responsible"),
        key: "responsible",
        width: 25,
      },
      {
        header: "responsible email",
        key: "responsibleEmail",
        width: 25,
      },
      {
        header: translation("acp_main_title"),
        key: "actions",
        width: 15,
      },
    ];

    const kpiConfiguration = allKpiAdministration.find(
      (kpi) => kpi.selectedToolId === selectedTool.id
    );
    const { goals = [] } = kpiConfiguration;

    const data = filteredOnlyInsights.map((insightInfo) => {
      const { responsibleMember } = insightInfo;
      const goalInfo = goals.find(
        (goal) => goal.id === insightInfo.kpiConfigurationGoalId
      );

      if (!goalInfo) {
        return null;
      }
      const result = getSingleIndicatorResults(
        goalInfo,
        kpiConfiguration.goalTargets
      );
      const actions = getTotalActionsByInsights(
        [insightInfo.id],
        allActionsPlans
      );

      const { courseTargetsDivision, goalPeriodTotalEvaluation } = result;

      const { displayCourseTargets } = getDisplayTargets(
        goalInfo,
        goalPeriodTotalEvaluation,
        courseTargetsDivision
      );

      const isDate = goalInfo.measurementUnity === "DATE";
      const gate = isDate
        ? displayTransformValue(displayCourseTargets.gate, "DATE")
        : displayCourseTargets.gate;
      const appropriated = isDate
        ? displayTransformValue(displayCourseTargets.appropriated, "DATE")
        : displayCourseTargets.appropriated;
      const exceeded = isDate
        ? displayTransformValue(displayCourseTargets.exceeded, "DATE")
        : goalInfo.target.exceeded;
      const lastPeriod = isDate
        ? displayTransformValue(result.dateTargets?.lastPeriod?.value, "DATE")
        : result.lastYearCourseEvaluation;
      const actual = isDate
        ? displayTransformValue(result.dateTargets?.actual?.value, "DATE")
        : result.goalPeriodTotalEvaluation;

      return {
        kpiId: goalInfo.id,
        cenary: insightInfo.question.theme.cenary.name,
        theme: insightInfo.question.theme.name,
        objective: insightInfo.question.text,
        indicator: insightInfo.text,
        measurementUnity: unityOptions.find(
          ({ value }) => value === goalInfo.measurementUnity
        )?.label,
        measurement: measurementOptions.find(
          ({ value }) => value === goalInfo.measurement
        )?.label,
        gate,
        appropriated,
        exceeded,
        lastPeriod,
        actual,
        score: result.goalPercentage,
        responsible: responsibleMember ? responsibleMember.name : "",
        responsibleEmail: responsibleMember ? responsibleMember.email : "",
        actions: actions.length,
      };
    });

    const timeStampString = moment().toJSON();

    const fakeHeader = {
      kpiId: "id",
      cenary: "cenary",
      theme: "theme",
      objective: "objective",
      indicator: "indicator",
      measurementUnity: "measurementUnity",
      measurement: "measurement",
      gate: "gate",
      appropriated: "appropriated",
      exceeded: "exceeded",
      lastPeriod: "lastPeriod",
      actual: "actual",
      score: "score",
      responsible: "responsible",
      responsibleEmail: "responsible email",
      actions: "actions",
    };

    simpleSaveAsExcel(
      data,
      headers,
      fakeHeader,
      `indicators-report-${timeStampString}`,
      [{ line: 1, fill: "eeeeee" }]
    );
  }

  return (
    <>
      <div className="custom-primary-tabs" id="tool-kpi-container">
        <Tabs
          id="kpiManagement"
          activeKey={activeTab}
          onSelect={(key) => setActiveTab(key)}
          style={{ marginTop: "15px" }}
        >
          {displayFilters()}
          <ButtonGeneratePdf />
          <ButtonExportExcel
            doExport={handleExcelExport}
            disabled={isLoading}
          />
          <Tab eventKey="performanceTab" title={performanceTabTitle}>
            <div className="name-tab-pdf">
              <i className="fas fa-tachometer-alt" />
              &nbsp;&nbsp; Performance
            </div>
            {displayTabContent(
              "performanceTab",
              <PerformanceEvaluations
                {...appendProps}
                actionsPlan={allActionsPlans}
              />
            )}
          </Tab>
          <Tab eventKey="goalsTab" title={processTabTitle}>
            <div className="name-tab-pdf">
              <i className="fas fa-tasks" />
              &nbsp;&nbsp; {translation("kpi_tab_administration_subthemes")}
            </div>
            {displayTabContent(
              "goalsTab",
              <ProcessKpisDetailsTable
                {...appendProps}
                actionsPlan={allActionsPlans}
              />
            )}
          </Tab>
          <Tab eventKey="detailsTab" title={detailsTabTitle}>
            <div className="name-tab-pdf">
              <i className="far fa-chart-bar" />
              &nbsp;&nbsp; {translation("kpi_tab_management_details")}
            </div>
            {displayTabContent(
              "detailsTab",
              <ConsolidationOverview {...appendProps} />
            )}
          </Tab>
          <Tab eventKey="actionsPlanTab" title={actionsPlanTabTitle}>
            <div className="name-tab-pdf">
              <i className="fas fa-list-ol" />
              &nbsp;&nbsp; {translation("acp_main_title")}
            </div>
            {displayTabContent(
              "actionsPlanTab",
              <ActionsPlanOverview
                allActionsPlans={groupAllActionsByRelated(allActionsPlans)}
                filteredInsights={filteredOnlyInsights}
                overviewTableTitle={translation("acp_main_title").toUpperCase()}
                overviewTableInsightsLabel={translation(
                  "kpi_table_text_goals"
                ).toUpperCase()}
                insightsTableTitle={translation(
                  "kpi_acp_table_indicators"
                ).toUpperCase()}
                insightsTableInsightsLabel={translation(
                  "kpi_options_gt_indicator"
                ).toUpperCase()}
                selectedTool={selectedTool}
                selectAndOpenInsight={selectAndOpenInsight}
                totalCount={
                  getIndicatorsWithActions(filteredOnlyInsights).count
                }
              />
            )}
          </Tab>
        </Tabs>
      </div>
      <LoadingDialog open={isLoading} />
    </>
  );
};

const mapStateToProps = (state) => {
  const { companyMembersAndParticipants } =
    getCompanyMembersAndParticipants(state);
  const { selectedTool } = getSelectedToolStates(state);

  const { allKpiAdministration, isLoading } = getKpiStates(state);
  const { allActionsPlans = [] } = getActionsPlanStates(state);

  return {
    selectedTool,
    allKpiAdministration,
    allActionsPlans,
    companyMembersAndParticipants,
    isLoading,
    loggedUser: state.user,
  };
};

export default connect(mapStateToProps)(KPI);
