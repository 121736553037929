import { updateAllList } from "../utils/reducerUtils";
import {
  UPDATE_ALL_CP_ANALYSIS_CONFIGURATION,
  GET_CP_ANALYSIS_MANAGEMENT_DATA,
  GET_CP_OVERVIEW_MANAGEMENT_DATA,
  SET_ANALYSIS_TOOL_TYPE,
  POST_COMPETITIVE_ANALYSIS_EVALUATION,
  UPDATE_COMPANY_DETAILS,
  DELETE_ANALYSIS_GROUP,
  DELETE_ANALYSIS_AMBIENT,
  DELETE_ANALYSIS_AREA,
  DELETE_EVALUATION_CRITERIA,
  GET_COMPETITIVE_ANALYSIS_POINTS,
  UPDATE_COMPETITIVE_ANALYSIS_EVALUATION,
  GET_ALL_COMPETITIVE_ANALYSIS_EVALUATIONS,
} from "../actions/types";
import {
  removeAnalysisAmbientByInfo,
  removeAnalysisGroupByInfo,
  removeAnalysisAreaByInfo,
  removeEvaluationCriteriaByInfo,
} from "../utils/cpAnalysis/entity";

const INITIAL_STATE = {
  all: [],
  allEvaluations: [],
  loading: false,
  overviewData: [],
};

const cpAnalysisReducer = (state = INITIAL_STATE, action) => {
  const { payload = {} } = action;

  const { all = [], allEvaluations = [], boardPoints = {} } = state;

  const handleGetEvaluation = (payload) => {
    return {
      ...state,
      allEvaluations: updateAllList(
        state.allEvaluations,
        [payload],
        "insightId"
      ),
    };
  };

  const getCurrentAdministration = (payload = {}) => {
    const { selectedToolId = "" } = payload;

    const currentAdministration =
      all.filter((adm) => adm.selectedToolId === selectedToolId)[0] || {};

    return currentAdministration;
  };

  const getCurrentEvaluation = (payload = {}) => {
    const { insightId = "" } = payload;

    const currentInsightEvaluation =
      allEvaluations.filter(
        (eva) => Number(eva.insightId) === Number(insightId)
      )[0] || {};

    return currentInsightEvaluation;
  };

  switch (action.type) {
    case UPDATE_ALL_CP_ANALYSIS_CONFIGURATION:
      return {
        ...state,
        all: updateAllList(state.all, payload, "selectedToolId"),
      };
    case GET_CP_ANALYSIS_MANAGEMENT_DATA:
      return {
        ...state,
        managementData: payload,
      };
    case GET_CP_OVERVIEW_MANAGEMENT_DATA:
      return {
        ...state,
        overviewData: payload,
      };
    case SET_ANALYSIS_TOOL_TYPE:
      return {
        ...state,
        currentToolType: payload,
      };
    case POST_COMPETITIVE_ANALYSIS_EVALUATION:
      return { ...handleGetEvaluation(payload) };
    case UPDATE_COMPETITIVE_ANALYSIS_EVALUATION:
      const { type = "", evaluations = [] } = payload;

      const currentInsightEvaluation = getCurrentEvaluation(payload);

      const updatedByType = currentInsightEvaluation[type.toLowerCase()] || [];

      const updated = {
        ...currentInsightEvaluation,
        [type.toLowerCase()]: updateAllList(updatedByType, evaluations, "id"),
      };

      return {
        ...state,
        allEvaluations: updateAllList(
          state.allEvaluations,
          [updated],
          "insightId"
        ),
      };
    case UPDATE_COMPANY_DETAILS:
      const { insightId = "", companyDetailsInfo } = payload;

      const currentInsightEvaluation2 = getCurrentEvaluation(payload);

      const updatedEvaluation = {
        insightId,
        ...currentInsightEvaluation2,
        companyDetails: { ...companyDetailsInfo },
      };

      const updatedList = updateAllList(
        state.allEvaluations,
        [updatedEvaluation],
        "insightId"
      );

      return {
        ...state,
        allEvaluations: updatedList,
      };
    case DELETE_ANALYSIS_GROUP:
      const updatedGroup = removeAnalysisGroupByInfo(
        getCurrentAdministration(payload),
        payload
      );

      return {
        ...state,
        all: updateAllList(state.all, [updatedGroup], "selectedToolId"),
      };
    case DELETE_ANALYSIS_AMBIENT:
      const updatedAmbient = removeAnalysisAmbientByInfo(
        getCurrentAdministration(payload),
        payload
      );

      return {
        ...state,
        all: updateAllList(state.all, [updatedAmbient], "selectedToolId"),
      };
    case DELETE_ANALYSIS_AREA:
      const updatedArea = removeAnalysisAreaByInfo(
        getCurrentAdministration(payload),
        payload
      );

      return {
        ...state,
        all: updateAllList(state.all, [updatedArea], "selectedToolId"),
      };
    case DELETE_EVALUATION_CRITERIA:
      const updatedCriteria = removeEvaluationCriteriaByInfo(
        getCurrentAdministration(payload),
        payload
      );

      return {
        ...state,
        all: updateAllList(state.all, [updatedCriteria], "selectedToolId"),
      };
    case GET_COMPETITIVE_ANALYSIS_POINTS:
      const {
        CONCURRENCE: concurrence = [],
        PESTAL: pestal = [],
        PRIORITIZATION: prioritization = [],
        SWOT: swot = [],
      } = payload || {};

      const {
        CONCURRENCE: currentConcurrence = [],
        PESTAL: currentPestal = [],
        PRIORITIZATION: currentPrioritization = [],
        SWOT: currentSwot = [],
      } = boardPoints || {};

      return {
        ...state,
        boardPoints: {
          CONCURRENCE: updateAllList(
            currentConcurrence,
            concurrence,
            "insightId"
          ),
          PESTAL: updateAllList(currentPestal, pestal, "insightId"),
          PRIORITIZATION: updateAllList(
            currentPrioritization,
            prioritization,
            "insightId"
          ),
          SWOT: updateAllList(currentSwot, swot, "insightId"),
        },
      };
    case GET_ALL_COMPETITIVE_ANALYSIS_EVALUATIONS:
      return {
        ...state,
        allEvaluations: updateAllList(
          state.allEvaluations,
          payload,
          "insightId"
        ),
      };
    default:
      return state;
  }
};

export default cpAnalysisReducer;
