import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

import {
  getCompAnalysisStates,
  getInsightStates,
  getSelectedToolStates,
} from "../../../customMapStates";

import {
  getCompetitiveAnalysisEvaluation,
  linkAnalysisGroup,
  sendCompanyDetails,
  sendCompetitiveAnalysisEvaluation,
  uploadLogo,
} from "../../../../actions/compAnalysisActions";
import { buildFormAdmConfigBody } from "../../../../utils/cpAnalysis/entity";
import InsightActionPlan from "../../../Common/ActionPlan/InsightActionPlan";
import ConcurrenceEvaluation from "./ConcurrenceEvaluation";
import PestalEvaluation from "./PestalEvaluation";
import PrioritizationEvaluation from "./PrioritizationEvaluation";
import SwotEvaluation from "./SwotEvaluation";

const defaultFormInitialCompAnalysis = buildFormAdmConfigBody({});

let CompetitiveAnalysis = (props) => {
  const {
    selectedTool = {},
    selectedInsight = {},
    currentToolType = "",
    currentInsightEvaluation,
    selectedInsightID = false,
    currentAdministration = {},
    getCompetitiveAnalysisEvaluation: getEvaluation,
  } = props;

  const [cpAnalysisAdministration, setCpAnalysisAdministration] = useState(
    defaultFormInitialCompAnalysis
  );

  useEffect(() => {
    if (!_.isEqual(currentAdministration, cpAnalysisAdministration))
      setCpAnalysisAdministration(currentAdministration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAdministration, selectedTool.id]);

  useEffect(() => {
    if (!selectedInsightID) return;
    getEvaluation(selectedInsightID, selectedTool.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CustomizationHandler = () => {
    const formToolType = currentToolType.toUpperCase();
    const { id: selectedToolId = "" } = selectedTool;

    const essentialProps = {
      ...props,
      uploadLogo,
      selectedToolId,
    };

    const customizations = {
      PESTAL: <PestalEvaluation {...essentialProps} />,
      SWOT: <SwotEvaluation {...essentialProps} />,
      PRIORITIZATION: <PrioritizationEvaluation {...essentialProps} />,
      CONCURRENCE: <ConcurrenceEvaluation {...essentialProps} />,
    };

    return customizations[formToolType] || <></>;
  };

  const MemoizedEvaluation = () => {
    return useMemo(() => {
      return <CustomizationHandler />;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentToolType, cpAnalysisAdministration, currentInsightEvaluation]);
  };

  return (
    <>
      {MemoizedEvaluation()}
      <InsightActionPlan
        insight={selectedInsight}
        selectedTool={selectedTool}
        dragabble
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { profile } = state;

  const { selectedTool = {} } = getSelectedToolStates(state);
  const { selectedInsightID = false, selectedInsight = {} } =
    getInsightStates(state);
  const {
    currentToolType = "",
    currentAdministration = {},
    currentInsightEvaluation = {},
  } = getCompAnalysisStates(state);

  return {
    profile,
    selectedTool,
    currentToolType,
    selectedInsight,
    selectedInsightID,
    currentAdministration,
    currentInsightEvaluation,
  };
};

CompetitiveAnalysis = injectIntl(
  connect(mapStateToProps, {
    getCompetitiveAnalysisEvaluation,
    sendCompetitiveAnalysisEvaluation,
    sendCompanyDetails,
    linkAnalysisGroup,
  })(CompetitiveAnalysis)
);

export { CompetitiveAnalysis };
