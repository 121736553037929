import _ from "lodash";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";

import { checkAccess } from "../../../../utils/accessLevels";
import utils from "../../../../utils/cpAnalysis";
import {
  getSwotCriteriaTranslation,
  getSwotScaleTranslation,
} from "../../../../utils/cpAnalysis/display";
import MaterialSingleSelect from "../../../Common/MaterialSingleSelect";

const {
  getSwotEvaluationFields,
  getSwotEvaluations,
  getEmptyConfiguration,
  handleEmptyConfiguration,
  handleEmptyGroupConfiguration,
} = utils;

const SwotEvaluation = ({
  insight,
  currentToolType = {},
  currentAdministration = {},
  currentInsightEvaluation = {},
  sendCompetitiveAnalysisEvaluation,
  selectedToolId,
  linkAnalysisGroup,
}) => {
  const [evaluations, setEvaluations] = useState({
    criteria: {},
    analysisGroupId: "",
  });

  useEffect(() => {
    const { competitiveAnalysisGroupId = "" } = insight;

    if (!currentInsightEvaluation?.id) return;

    const syncEvaluation = getSwotEvaluations(currentInsightEvaluation);

    setEvaluations({
      ...syncEvaluation,
      analysisGroupId: competitiveAnalysisGroupId,
    });
  }, [currentInsightEvaluation, insight]);

  const {
    evaluationCriterias = [],
    scales = [],
    analysisGroups = [],
  } = getSwotEvaluationFields(currentAdministration, currentToolType);

  const emptyConfiguration = getEmptyConfiguration(evaluationCriterias, scales);
  const emptyGroupConfiguration = analysisGroups.length === 0;

  const scalesOptions = scales.map((scale) => {
    return { value: scale.id, label: getSwotScaleTranslation(scale.title) };
  });

  const groupsOptions = analysisGroups.map((group) => {
    return { value: group.id, label: group.title };
  });

  const handleChangeGroupEvaluation = (value = "") => {
    setEvaluations({ ...evaluations, analysisGroupId: value });
    linkAnalysisGroup(insight.id, value);
  };

  const handleChangeEvaluation = ({ id }, { value }) => {
    setEvaluations({
      ...evaluations,
      criteria: { ...evaluations.criteria, [id]: value },
    });
  };

  const getFirstEvaluationBody = () => {
    const { criteria = {} } = evaluations;

    return evaluationCriterias.map(({ id: evaluationCriteriaId }) => {
      const scale = Number(criteria[evaluationCriteriaId]);

      return {
        evaluationCriteriaId,
        scaleId: scale === 0 ? null : scale,
        type: "SWOT",
      };
    });
  };

  const searchEvaluation = (evaluationCriteriaId = 0) => {
    const { swot = [] } = currentInsightEvaluation;

    return (
      swot.filter(
        (evaluation) =>
          Number(evaluation.evaluationCriteriaId) ===
          Number(evaluationCriteriaId)
      )[0] || false
    );
  };

  const getRestEvaluationBody = () => {
    let restEvaluationBody = [];
    let reSendEvaluation = false;

    const { criteria = {} } = evaluations;

    Object.keys(criteria).forEach((criteriaId) => {
      const scaleId = criteria[criteriaId];

      const { scaleId: currentScaleId, id = false } =
        searchEvaluation(criteriaId);

      if (!id) reSendEvaluation = true;
      if (Number(currentScaleId) === Number(scaleId)) return;

      restEvaluationBody.push({
        evaluationCriteriaId: Number(criteriaId),
        scaleId,
        id,
        type: "SWOT",
      });
    });

    return { restEvaluationBody, reSendEvaluation };
  };

  const handleSave = () => {
    const { restEvaluationBody = [], reSendEvaluation = false } =
      getRestEvaluationBody();

    sendCompetitiveAnalysisEvaluation(
      insight.id,
      {
        type: "SWOT",
        evaluations: reSendEvaluation
          ? getFirstEvaluationBody()
          : restEvaluationBody,
      },
      selectedToolId,
      reSendEvaluation ? "POST" : "PUT"
    );
  };

  const canSaveAppears = () => {
    if (emptyConfiguration && emptyGroupConfiguration) return false;

    const { criteria = {}, analysisGroupId } = getSwotEvaluations(
      currentInsightEvaluation
    );

    const {
      criteria: currentCriteria = {},
      analysisGroupId: currentAnalysisGroupId,
    } = evaluations;

    if (
      _.isEqual(criteria, currentCriteria) &&
      analysisGroupId === currentAnalysisGroupId
    )
      return false;

    return true;
  };

  const saveButtonHandler = () => {
    if (!canSaveAppears()) return null;

    return (
      <Button
        className="btn btn-purple pull-right"
        onClick={() => handleSave()}
      >
        Salvar
      </Button>
    );
  };

  const displayAnalysisGroupFields = () => {
    if (emptyGroupConfiguration) return null;

    const { analysisGroupId = "" } = evaluations;

    return (
      <Col md={6}>
        <MaterialSingleSelect
          id="analysisGroups"
          label="Grupo de Análise"
          value={analysisGroupId}
          options={groupsOptions}
          onChange={(e) =>
            checkAccess([
              "CREATOR",
              "RESPONSIBLE",
              "COLLABORATOR",
              "MANAGER",
              "LEADER",
              "MODERATOR",
              "ADMIN",
              "OWNER",
            ]) && handleChangeGroupEvaluation(e.target.value)
          }
        />
      </Col>
    );
  };

  const displayEvaluationFields = () => {
    const { criteria: criteriaState = {} } = evaluations;

    return evaluationCriterias.map((criteria, index) => {
      const { id } = criteria;

      return (
        <Col md={6} key={index}>
          <MaterialSingleSelect
            id={criteria.title}
            label={getSwotCriteriaTranslation(criteria.title)}
            value={criteriaState[id] || ""}
            options={scalesOptions}
            onChange={(e) =>
              checkAccess([
                "CREATOR",
                "RESPONSIBLE",
                "COLLABORATOR",
                "MANAGER",
                "LEADER",
                "MODERATOR",
                "ADMIN",
                "OWNER",
              ]) && handleChangeEvaluation(criteria, e.target)
            }
          />
        </Col>
      );
    });
  };

  return (
    <div className="box box-primary">
      <div className="box-header with-border">
        <i className="fas fa-tasks" />
        <h3 className="box-title">Avaliação SWOT</h3>
        {saveButtonHandler()}
      </div>
      <div className="box-body">
        <Row>{displayEvaluationFields()}</Row>
        <Row>{handleEmptyConfiguration(emptyConfiguration)}</Row>
      </div>
      <div className="box-header with-border">
        <i className="fas fa-tasks" />
        <h3 className="box-title">Seleção de Grupo da Análise</h3>
      </div>
      <div className="box-body">
        <Row>{displayAnalysisGroupFields()}</Row>
        <Row>{handleEmptyGroupConfiguration(emptyGroupConfiguration)}</Row>
      </div>
    </div>
  );
};

export default SwotEvaluation;
