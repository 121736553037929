import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Select from "react-select";
import { checkGreaterThan } from "../../../../../../../utils/MathUtils";
import {
  evaluationColors,
  getWorkflowEvaluationFields,
  workflowCommentTags,
} from "../../../../../../../utils/skillsAssessment/constants";
import WorkflowEvaluationCol from "../../../../../../Common/WorkflowEvaluationCol";
import WorkflowComments from "./WorkflowComments";

const selectStyles = {
  container: (styles) => {
    return {
      ...styles,
      height: "100%",
    };
  },
  control: (styles) => {
    return {
      ...styles,
      height: "100%",
    };
  },
};

const Description = ({ title, description }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        padding: "10px",
        fontSize: "11px",
      }}
      title={description}
    >
      {title}
    </div>
  );
};

const WorkflowDataTable = ({
  selectedToolId,
  workflow,
  scaleOptions,
  enabledFields,
  handleWorkflowUpdate,
  blockUpdates,
  participant,
  hideComments,
  maxScale,
  minScale,
  maxPotential,
  minPotential,
  loggedUser,
  isRequired = true,
}) => {
  const [showComments, setShowComments] = useState(null);

  const defaultEvaluationFields = getWorkflowEvaluationFields();
  const haveNoWorkflow = !workflow || workflow.length === 0;

  const { insight } = participant;

  const toggleComments = (workflowId) => {
    const newShowComments = showComments === workflowId ? null : workflowId;

    setShowComments(newShowComments);
  };

  const commentsCol = (workflowId) => {
    const open = showComments === workflowId;
    const iconClass = open ? "fas fa-caret-down" : "fas fa-caret-right";

    return (
      !hideComments && (
        <td
          onClick={() => toggleComments(workflowId)}
          align="center"
          style={{ cursor: "pointer" }}
          className="input-td"
        >
          <i className={iconClass} />
        </td>
      )
    );
  };

  const headCols = () => {
    return defaultEvaluationFields.map(({ value, label, type }) => {
      if (enabledFields[value] && !enabledFields[value].hide)
        return (
          <td
            width={type === "float" ? "110" : "220"}
            key={value}
            style={{ fontSize: "10px" }}
          >
            {label}
          </td>
        );

      return null;
    });
  };

  const getFloatCol = (workflowInfo, field, tabIndex) => {
    const max = field === "potential" ? maxPotential : maxScale;
    const min = field === "potential" ? minPotential : minScale;

    const handleSelectValue = (newValue) => {
      if (!blockUpdates) {
        const validMinimum =
          Number(newValue) === 0 || checkGreaterThan(newValue, min)
            ? newValue
            : "";
        const validNewValue = checkGreaterThan(validMinimum, max)
          ? max
          : validMinimum;

        handleWorkflowUpdate(workflowInfo, field, validNewValue);
      }
    };

    const { hide = true, disabled = true } = enabledFields[field] || {};
    const scaleSlug =
      field !== "potential" ? "evaluationScale" : "potentialScale";

    return (
      !hide && (
        <WorkflowEvaluationCol
          key={workflowInfo.id}
          field={`wfield-${workflowInfo.id}`}
          value={workflowInfo[field]}
          updateValue={handleSelectValue}
          progressColor={evaluationColors[field].color}
          maxValue={max}
          disabled={disabled}
          options={scaleOptions[scaleSlug]}
          tabIndex={tabIndex}
        />
      )
    );
  };

  const evaluationCols = (workflowInfo, field, type, tabIndex) => {
    if (type === "float") return getFloatCol(workflowInfo, field, tabIndex);

    const { hide = true, disabled = true } = enabledFields[field] || {};
    const updateValue = (newValue) => {
      if (!blockUpdates && !disabled) {
        const validValue = newValue !== "empty" ? newValue : "";
        handleWorkflowUpdate(workflowInfo, field, validValue);
      }
    };

    const currentValue =
      !workflowInfo[field] && workflowInfo[field] !== 0
        ? "empty"
        : workflowInfo[field];

    const scaleSlug =
      field !== "potential" ? "evaluationScale" : "potentialScale";

    const options = [
      {
        value: "empty",
        label: `${isRequired ? "* " : ""}- Selecione uma opção -`,
      },
      ...scaleOptions[scaleSlug],
    ];
    const currentOption = options.find((option) => {
      return option.value === currentValue;
    });

    return (
      !hide && (
        <td className="input-td">
          <Select
            defaultValue={currentOption}
            value={currentOption}
            onChange={(option) => updateValue(option.value)}
            isDisabled={disabled}
            options={options}
            styles={selectStyles}
            menuShouldScrollIntoView={false}
          />
        </td>
      )
    );
  };

  return (
    <table className="simple-table workflow-table">
      <thead>
        <tr className="small-font" style={{ fontWeight: "bold" }}>
          {!hideComments && (
            <td title="Comentários/Observações">
              <i className="far fa-comments" />
            </td>
          )}
          <td style={{ fontSize: "12px" }}>ÁREA DE COMPETÊNCIA</td>
          <td style={{ fontSize: "12px" }}>COMPETÊNCIA</td>
          {headCols()}
        </tr>
      </thead>
      <tbody>
        {workflow?.map((workflowInfo) => {
          const { skill } = workflowInfo;

          const displayComments = showComments === workflowInfo.id;

          return (
            <React.Fragment key={workflowInfo.id}>
              <tr>
                {commentsCol(workflowInfo.id)}
                <td className="input-td">
                  <Description
                    title={skill?.competenceArea?.title}
                    description={skill?.competenceArea?.description}
                  />
                </td>
                <td className="input-td">
                  <Description
                    title={skill?.title}
                    description={skill?.description}
                  />
                </td>
                {defaultEvaluationFields.map(({ value, type }, mapIndex) => {
                  return (
                    <React.Fragment key={value}>
                      {evaluationCols(workflowInfo, value, type, mapIndex + 1)}
                    </React.Fragment>
                  );
                })}
              </tr>
              {displayComments && !hideComments && (
                <tr className="block-hover">
                  <td colSpan="100">
                    <div style={{ padding: "15px" }}>
                      <h4>Comentários e Observações</h4>
                      <WorkflowComments
                        selectedToolId={selectedToolId}
                        insight={insight}
                        loggedUser={loggedUser}
                        tags={workflowCommentTags(skill).all}
                        filter={(newComments) => newComments}
                      />
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          );
        })}
        {haveNoWorkflow && (
          <tr>
            <td colSpan="100">
              <h5 align="center" style={{ color: "#666" }}>
                Nenhuma Competência foi adicionada para o Perfil deste
                Participante.
              </h5>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

WorkflowDataTable.propTypes = {
  workflow: PropTypes.array.isRequired,
  enabledFields: PropTypes.object.isRequired,
  scaleOptions: PropTypes.object.isRequired,
  participant: PropTypes.object,
  handleWorkflowUpdate: PropTypes.func,
  blockUpdates: PropTypes.bool,
  hideComments: PropTypes.bool,
};

WorkflowDataTable.defaultProps = {
  handleWorkflowUpdate: () => {},
  blockUpdates: false,
  hideComments: false,
  participant: {},
};

function areEqual(prevProps, nextProps) {
  if (!_.isEqual(prevProps.participant, nextProps.participant)) return false;
  if (!_.isEqual(prevProps.workflow, nextProps.workflow)) return false;
  return true;
}

export default React.memo(WorkflowDataTable, areEqual);
