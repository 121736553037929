import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  updateKpiAdministration,
  updateKpiIndicator,
} from "../../../../actions/kpiActions";
import { checkAccess } from "../../../../utils/accessLevels";
import kpiUtils from "../../../../utils/kpis";
import {
  useSelectedIndicator,
  useSimpleToolAdministration,
} from "../../../../utils/kpis/hooks";
import InsightActionPlan from "../../../Common/ActionPlan/InsightActionPlan";
import InsightSaveButton from "../../../Common/InsightSaveButton";
import GoalDescription from "../../../Common/Kpi/insight/GoalDescription";
import GoalEvaluation from "../../../Common/Kpi/insight/GoalEvaluation";
import { getKpiStates, getSelectedToolStates } from "../../../customMapStates";
import { useCustomModal } from "../../hooks/CustomModalSave";

const { getGoalIndicatorInfoById, getNewIndicatorErrors } = kpiUtils;

const InsightCustomField = ({
  allKpiAdministration = [],
  selectedTool = {},
  insight = {},
  isLoading: isLoadingTool,
}) => {
  const { kpiAdministration = {} } = useSimpleToolAdministration({
    allKpiAdministration,
    selectedTool,
    isLoading: isLoadingTool,
  });
  const { handleSetConfigsModal, configsModal } = useCustomModal();
  const [loading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const { goals: originalGoals } = kpiAdministration;
  const { kpiConfigurationGoalId = null } = insight;

  const isLoading = loading || isLoadingTool;

  const { goalDescriptions, setGoalDescriptions, changes, setController } =
    useSelectedIndicator({
      goals: originalGoals,
      kpiConfigurationGoalId,
      isLoading: isLoadingTool,
    });

  const goalTargetConfigurations = kpiAdministration.goalTargets;
  const hasAnyChanges =
    changes.length > 0 && !isLoading && goalDescriptions?.id;

  const handleSaveGoal = async (updatedGoal) => {
    if (kpiAdministration && kpiAdministration.id) {
      await dispatch(
        updateKpiIndicator(updatedGoal, kpiAdministration)
      ).finally(() => {
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    if (hasAnyChanges && !configsModal?.show) {
      const configs = {
        show: true,
        next: false,
        hideAfter: false,
        title: "Tem certeza que deseja sair sem salvar as alterações?",
        onSaveText: "Voltar a edição",
      };
      handleSetConfigsModal(configs);
    } else if (!hasAnyChanges && configsModal?.show) {
      handleSetConfigsModal({
        ...configsModal,
        show: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAnyChanges]);

  const handleSaveIndicator = async () => {
    setIsLoading(true);
    const data = kpiAdministration;

    if (data && data.id) {
      const editingGoalIndex = getGoalIndicatorInfoById(
        goalDescriptions.id,
        data.goals,
        true
      );

      if (editingGoalIndex >= 0) {
        const newUpdatedGoals = [...data.goals];

        const targetId = data.goals[editingGoalIndex].target.id;

        newUpdatedGoals[editingGoalIndex] = {
          ...data.goals[editingGoalIndex],
          ...goalDescriptions,
        };

        if (targetId) {
          newUpdatedGoals[editingGoalIndex].target = {
            id: targetId,
            ...newUpdatedGoals[editingGoalIndex].target,
          };
        }

        const updatingGoal = newUpdatedGoals[editingGoalIndex];

        if (selectedTool && selectedTool.id) {
          if (kpiAdministration && kpiAdministration.id) {
            await dispatch(updateKpiIndicator(updatingGoal, kpiAdministration));
          }
          setController((controller) => {
            return { ...controller, firstMount: false };
          });
        }
      }
    }

    setIsLoading(false);
  };

  const formErrors = getNewIndicatorErrors(goalDescriptions, originalGoals);

  const responsibleAccess = checkAccess(
    ["RESPONSIBLE", "COLLABORATOR"],
    null,
    insight.id
  );
  const creatorAccess = checkAccess(["CREATOR"], null, insight.id);
  const administrativeAccess = checkAccess([
    "OWNER",
    "ADMIN",
    "MODERATOR",
    "MANAGER",
    "LEADER",
  ]);

  const blockAllChanges =
    !responsibleAccess && !administrativeAccess && !creatorAccess;

  const hideActions = !(
    responsibleAccess ||
    administrativeAccess ||
    creatorAccess
  );

  const appendProps = {
    insight,
    goalDescriptions,
    setGoalDescriptions,
    goalTargetConfigurations,
    SaveButton: (
      <InsightSaveButton
        show={changes.length > 0}
        onSave={handleSaveIndicator}
        loading={isLoading}
      />
    ),
    formErrors,
    blockAllChanges,
    hideActions,
    zeroWhenNegative: kpiAdministration.zeroWhenNegative,
    kpiAdministration,
    selectedToolId: selectedTool.id,
    handleSaveGoal,
    isLoading,
    setIsLoading,
  };

  return (
    <div className="row" style={isLoading ? { opacity: 0.7 } : {}}>
      <div className="row">
        <GoalDescription {...appendProps} />
      </div>
      <div className="row">
        <GoalEvaluation {...appendProps} />
      </div>
      <div className="row">
        <InsightActionPlan
          insight={insight}
          selectedTool={selectedTool}
          dragabble
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { allKpiAdministration, isLoading } = getKpiStates(state);
  const { selectedTool } = getSelectedToolStates(state);

  return {
    allKpiAdministration,
    selectedTool,
    isLoading,
  };
};

const areEqual = (prev, next) => {
  if (prev.isLoading !== next.isLoading) return false;
  if (!_.isEqual(prev.allKpiAdministration, next.allKpiAdministration))
    return false;
  if (!_.isEqual(prev.insight, next.insight)) return false;
  return true;
};

const Kpi = connect(mapStateToProps, { updateKpiAdministration })(
  React.memo(InsightCustomField, areEqual)
);

export { Kpi };
